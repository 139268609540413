import type { UserType } from "@model/contact";
import type { IProperty } from "@model/property";
import { IDeal } from "./model.deal";

export interface ITask {
  id: number | string;
  agent_id?: number | null;
  agent?: UserType;
  deal_id?: number | null;
  deal?: IDeal;
  user_id?: number | null;
  user?: UserType;
  property_ids?: number[] | [];
  properties?: IProperty[];
  project_ids?: number[] | [];
  projects?: any[];
  type: TASK_TYPE;
  title?: string;
  description?: string;
  is_done?: boolean;
  is_overdue?: boolean;
  scheduled_at?: string;
  duration?: number;
  ended_at?: string;
  created_at?: string;
  updated_at?: string;
  relation?: string;
  add_to_calendar?: boolean;
  google_event_id?: string;
  creator?: UserType;
  created_by?: number;
  is_editable?: boolean;
  country_code?: string;
  // content
}

export enum TASK_TYPE {
  NOTES = "notes",
  TASK = "task",
  VIEWING = "viewing",
  DEAL = "deal"
}

export enum TASK_TAB {
  TODAY = "today",
  UPCOMING = "upcoming",
  OVERDUE = "overdue",
  DONE = "done"
}

export const TASK_MAIN_ROUTES = ["/tasks", "/viewing-deals"];
