import React, { ReactNode } from "react";
import { AppInputOptions, selectOptionType } from "@model/app";
import { tabType } from "./components/table.settings";

export type CustomRenderers<T extends MinTableItem> = Partial<
  Record<keyof T, (item: T) => React.ReactNode>
>;
export type PrimitiveType = string | symbol | number | boolean;

// minimum prop need to provide
export interface MinTableItem {
  id: number;
}

export interface ITableProps<T extends MinTableItem> {
  endpoint: string;
  tableName?: string;
  defaultConfig: TableConfig;
  onCreate?: () => void;
  onCSVDownload?: () => void;
  actionOptions?: (item: T) => ReactNode;
  customRenderers?: CustomRenderers<T>;
  tabSettings?: tabType[];
  setStorage: (newConfig: TableStorageConfig) => void;
  onApplyFilters?: () => void;
  isSelectedAllItems?: boolean;
  onFetchSucceeded?: (total: number) => void;
  onToggleItem?: (checked: boolean, id: number) => void;
  onToggleAllItems?: (items: number[]) => void;
  batchEditing?: ReactNode;
  children?: ReactNode;
  renderCardTemplate?: (item: T) => ReactNode;
  batchItems?: (props: any) => ReactNode;
  quickFilter?: (
    queries: QueryConfigType,
    setQueries: React.Dispatch<React.SetStateAction<QueryConfigType>>
  ) => JSX.Element;
}

export const columnFiltered = (columns: ColumnConfigType[]) => {
  return columns.filter((col) => col.visible);
};

export const isPrimitive = (value: any): value is PrimitiveType => {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean" ||
    typeof value === "symbol"
  );
};

export const rowOptions = [5, 10, 25, 50, 100, 200];

export type FilterType = any | null;

export type QueryConfigType = {
  table?: string;
  per_page: number;
  page: number;
  filters?: FilterType;
  sort?: string;
  sort_by?: string;
  search?: string;
  pipeline_id?: number; // for deal page
  view_user_id?: number | null;
};

export type ColumnFilterType = {
  type: string | null;
  value: string | null;
  condition?: string;
  relationship?: string;
  options?: selectOptionType[];
  endPoint?: string;
  showMonthYearPicker?: boolean;
  dateFormat?: string;
  placeholderText?: string;
  allowTextInput?: boolean;
  keyInputOptions?: string;
};

export type ColumnConfigType = {
  key: string;
  label: string;
  visible: boolean;
  columnDisplay: boolean;
  filters: ColumnFilterType | null;
  sortable: boolean;
  position?: string;
};

export enum TableLayoutType {
  table = "table",
  card = "card"
}
export interface TableConfig {
  filters: ColumnConfigType[];
  columns: ColumnConfigType[];
  queries: QueryConfigType | null;
  layout?: TableLayoutType | null;
  view_user_id?: number | null;
}
export interface TableStorageConfig {
  columns: ColumnConfigType[];
  queries: QueryConfigType | null;
  layout?: TableLayoutType | null;
  view_user_id?: number | null;
}

export interface ITableQuickFilter {
  queries: QueryConfigType;
  setQueries: React.Dispatch<React.SetStateAction<QueryConfigType>>;
}

export const getFilterNotifyNumber = (filters: any) => {
  let filterNum = 0;

  if (!filters) return filterNum;

  filterNum = Object.keys(filters).filter(
    (filterKey: string) => !["feature_id"].includes(filterKey)
  ).length;
  if (filters?.feature_id?.and) {
    filterNum += filters?.feature_id?.and?.length;
  }

  return filterNum;
};

// export reusable module
export { Sorting as SortingIcon } from "./components/table.tools";
export { default as QuickSearch } from "./features/quick-toolbar/quick-search";
