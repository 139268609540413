import { get } from "@utils/api";
import { ICoordinate } from "@model/geo";

type GeoLatLonType = {
  id: string;
  name: string;
  formatted_address: string;
  coordinates: ICoordinate;
};

class GetGeoDistrictDropdown {
  endpoint: string;
  queries: object;

  constructor(queries: object) {
    this.endpoint = "geo/search/district-by-name";
    this.queries = queries;
  }

  static call(queries: object) {
    return new GetGeoDistrictDropdown(queries).getGeoDistrict();
  }

  async getGeoDistrict() {
    const data = (await get({
      path: this.endpoint,
      queries: this.queries
    })) as GeoLatLonType[];

    if (!data.length) {
      return [];
    }

    return data.map((item) => ({
      label: item.name,
      value: item.id,
      coordinates: item.coordinates.coordinates
    }));
  }
}

export default GetGeoDistrictDropdown;
