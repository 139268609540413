import { toast } from "react-toastify";

const success = (msg: string) => {
  return toast.success(msg, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
    draggable: false
  });
};

const error = (msg = "Something was wrong!") => {
  return toast.error(msg, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
    draggable: false
  });
};

const ToastComponent = { success, error };

export default ToastComponent;
