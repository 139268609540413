import { AppPermissions, CountryCode, UserRole } from "@model/app";
import { UserRules } from "@models/model.contact";
// import { LIMITED_EMAIL_USERS } from "@model/email";
import { State } from "@state/index";

export const selectUserId = (state: State) => state.auth.userData?.id || null;
export const selectUserName = (state: State) => state.auth.userData?.name || "Lazudi Agent";

export const selectUserProfile = (state: State) => state.auth.userData;

export const selectForm = (state: State) => state.app.formControl;
export const selectModal = (state: State) => state.app.modal;

export const selectSuperAdmin = (state: State) => {
  const user = state.auth.userData;
  return user?.role === UserRole.superAdmin;
};

export const selectAdmin = (state: State) => {
  const user = state.auth.userData;
  return (
    user?.role === UserRole.admin ||
    user?.role === UserRole.customerSuccess ||
    user?.role === UserRole.superAdmin
  );
};

export const selectAgent = (state: State) => {
  const user = state.auth.userData;
  return user?.role === UserRole.agent;
};

export const selectContributor = (state: State) => {
  const user = state.auth.userData;
  return user?.role === UserRole.contributor;
};

export const selectAffiliate = (state: State) => {
  const user = state.auth.userData;
  return user?.role === UserRole.affiliate;
};

export const selectUserRole = (state: State) => {
  const user = state.auth.userData;
  return user?.role;
};

export const selectBlogMaintainer = (state: State) => {
  const user = state.auth.userData;
  if (!user?.role) return false;

  return AppPermissions.blogManagement.includes(user.role);
};

export const selectGeoMaintainer = (state: State) => {
  const user = state.auth.userData;
  if (!user?.role) return false;

  return AppPermissions.geoManagement.includes(user.role);
};

// user rules
export const selectUserRuleIsHidePropertyOwnerNoteAdditionalContact = (state: State) => {
  const user = state.auth.userData;
  if (user) {
    return user.user_rules.includes(UserRules.hidePropertyOwnerAndNoteAndAdditionalContact);
  }
  return false;
};

export const selectListingAdmin = (state: State) => {
  const user = state.auth.userData;
  if (user) {
    return user.user_rules.includes(UserRules.listingAdmin);
  }
  return false;
};

export const selectTaskManager = (state: State) => {
  const user = state.auth.userData;
  if (user) {
    return user.user_rules.includes(UserRules.taskManager);
  }
  return false;
};

// preferences
export const selectPreferences = (state: State) => {
  return state.app.preferences;
};

export const selectDefaultCountry = (state: State) => {
  return state.app.preferences?.defaultCountry || CountryCode.TH;
};

// SUPER ADMIN
export const selectIsSuperAdmin = (state: State) => {
  return state.app.superAdmin.is_super_admin;
};
export const selectIsSystemSuperAdmin = (state: State) => {
  return state.app.superAdmin.is_system_super_admin;
};
export const selectViewUserId = (state: State) => {
  return state.app.superAdmin?.view_user?.value || null;
};
export const selectViewUser = (state: State) => {
  return state.app.superAdmin?.view_user;
};

// GLOBAL COMPONENT
export const selectGlobalEmail = (state: State) => {
  return state.app.globalEmail;
};

// GLOBAL COMPONENT
export const selectNotificationAlert = (state: State) => {
  return state.notification.notificationAlert;
};

export const selectNotificationMenu = (state: State) => {
  return state.notification?.notificationMenu;
};
// export const selectUnreadNotificationCount = (state: State) => {
//   return state.notification.unReadCount
// };

export const selectCanAccessEmail = (state: State) => {
  return true;
  // const user = state.auth.userData;
  // return LIMITED_EMAIL_USERS.includes(user?.email || "");
};

export const selectInputOptions = (state: State) => state.app.inputOptions;

export const selectTeamPartners = (state: State) => state.auth.userData?.team_partners || [];

export const selectUserPartners = (state: State) => state.auth.userData?.user_partners || [];
