import { useMemo, useState } from "react";
import { classNames } from "@utils/helpers";
import { ColumnConfigType } from "../index";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import CustomButton from "@views/components/buttons/button.custom";
import Modal from "@views/components/modal";
import ConfirmDialog from "@views/components/modal.confirmation";
import useModal from "@utils/hooks/useModal";

export type tabType = {
  key: string;
  current: boolean;
  name: string;
  fields: any;
};

interface ITableSettings<T> {
  tabSettings?: tabType[];
  tableColumns: ColumnConfigType[];
  defaultColumns?: ColumnConfigType[];
  onUpdateColumns: (newColumns: ColumnConfigType[]) => void;
}

interface DragHandleProps {
  provided: any;
}

const DragHandle: React.FC<DragHandleProps> = ({ provided }) => (
  <div {...provided.dragHandleProps} className="cursor-[row-resize]">
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="grip-dots"
      className="w-4 h-4 text-gray-400 svg-inline--fa fa-grip-dots cursor-[row-resize]"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M384 128C410.5 128 432 149.5 432 176C432 202.5 410.5 224 384 224C357.5 224 336 202.5 336 176C336 149.5 357.5 128 384 128zM224 128C250.5 128 272 149.5 272 176C272 202.5 250.5 224 224 224C197.5 224 176 202.5 176 176C176 149.5 197.5 128 224 128zM64 224C37.49 224 16 202.5 16 176C16 149.5 37.49 128 64 128C90.51 128 112 149.5 112 176C112 202.5 90.51 224 64 224zM384 288C410.5 288 432 309.5 432 336C432 362.5 410.5 384 384 384C357.5 384 336 362.5 336 336C336 309.5 357.5 288 384 288zM224 384C197.5 384 176 362.5 176 336C176 309.5 197.5 288 224 288C250.5 288 272 309.5 272 336C272 362.5 250.5 384 224 384zM64 288C90.51 288 112 309.5 112 336C112 362.5 90.51 384 64 384C37.49 384 16 362.5 16 336C16 309.5 37.49 288 64 288z"
      ></path>
    </svg>
  </div>
);

interface SortableItemProps {
  col: ColumnConfigType;
  index: number;
  onVisibleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SortableItem: React.FC<SortableItemProps> = ({ col, index, onVisibleChange }) => {
  return (
    <Draggable draggableId={col.key} index={index}>
      {(provided) => (
        <div
          className="relative z-50 flex items-center space-x-3"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <DragHandle provided={provided} />
          <div className="flex items-center h-5">
            <input
              id={`col_${col.key}`}
              name={col.key}
              type="checkbox"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onVisibleChange(e)}
              checked={col.visible}
              className="w-4 h-4 text-purple-600 border-gray-300 rounded focus:ring-purple-500"
            />
          </div>
          <div className="text-sm">
            <label htmlFor={`col_${col.key}`} className="font-medium text-gray-700 capitalize">
              {col.label || col.key}
            </label>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default function TableSettings<T>({
  tabSettings,
  tableColumns,
  defaultColumns,
  onUpdateColumns
}: ITableSettings<T>) {
  const [openModal, closeModal, onConfirm, , modalState] = useModal();
  const [tabs, setTabs] = useState(tabSettings);
  const filteredColumns = useMemo(
    () => [...tableColumns].filter((i: ColumnConfigType) => i.columnDisplay),
    [tableColumns]
  );

  const onTabChange = (key: string) => {
    setTabs((prev) => {
      return prev?.map((p) => {
        if (p.key === key) return { ...p, current: true };
        return { ...p, current: false };
      });
    });
  };

  const onVisibleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCol =
      tableColumns?.map((col) => {
        if (col.key === e.target.name) {
          return { ...col, visible: e.target.checked };
        }
        return col;
      }) || [];
    onUpdateColumns(newCol);
  };

  const activeTab = tabs?.find((t) => !!t.current)?.key || null;

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const startIndex = result.source.index;
    const endIndex = result.destination.index;

    // reorder columns
    const newColumns = [...filteredColumns];
    const [removed] = newColumns.splice(startIndex, 1);
    newColumns.splice(endIndex, 0, removed);

    onUpdateColumns([...tableColumns.filter((i) => i.position === "fixed"), ...newColumns]);
  };

  const resetConfig = async () => {
    const confirmed = await openModal(`reset_setting`);

    if (confirmed) {
      onUpdateColumns([...(defaultColumns ?? [])]);
    }
  };

  return (
    <div>
      <nav
        className="flex px-6 -mb-px space-x-6 border-b border-gray-200 justify-between items-center"
        x-descriptions="Tab component"
      >
        {tabs?.map((tab) => (
          <div
            key={tab.name}
            onClick={() => onTabChange(tab.key)}
            className={classNames(
              tab.current
                ? "border-purple-500 text-purple-600"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
              "whitespace-nowrap pb-2 px-1 border-b-2 font-medium text-sm cursor-pointer"
            )}
          >
            {tab.name}
          </div>
        ))}
        <CustomButton
          onClick={resetConfig}
          variant="primary"
          label="Reset"
          size="sm"
          className="mb-2"
        />
        <Modal modalKey="reset_setting">
          <ConfirmDialog
            onConfirm={onConfirm}
            onCancel={closeModal}
            options={{
              title: "Restore default settings?",
              subTitle: "Are you sure you want to restore the default settings?",
              btnStyle: "negative"
            }}
          />
        </Modal>
      </nav>

      {/* content */}
      {activeTab === "columns" && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="columns">
            {(provided) => (
              <div
                className="px-6 my-6 space-y-3"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {filteredColumns.map((col, index) => (
                  <SortableItem
                    key={`item-${col.key}`}
                    index={index}
                    col={col}
                    onVisibleChange={onVisibleChange}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
}
