import { useState, useEffect } from "react";

/** Types */
interface Column {
  key: string;
  [key: string]: any;
}

interface WithColumns {
  columns: Column[];
  [key: string]: any;
}

interface StorageMetadata {
  storageKey: string;
}

type ReturnType<T> = [T, React.Dispatch<React.SetStateAction<T>>, StorageMetadata];


/** Helpers */ 
const hasColumns = (value: any): value is WithColumns => {
  return value && typeof value === 'object' && 'columns' in value;
};

const compareColumns = (initialColumns: Column[], storedColumns: Column[]) => {
  const newColumns = initialColumns.filter(
    initCol => !storedColumns.some(storedCol => storedCol.key === initCol.key)
  );

  const removedColumns = storedColumns.filter(
    storedCol => !initialColumns.some(initCol => initCol.key === storedCol.key)
  );

  return { newColumns, removedColumns };
};

const updateColumns = (storedValue: WithColumns, changes: { 
  newColumns: Column[], 
  removedColumns: Column[] 
}): WithColumns => {
  const { newColumns, removedColumns } = changes;
  
  let updatedColumns = [...storedValue.columns];
  
  if (newColumns.length) {
    updatedColumns = [...updatedColumns, ...newColumns];
  }
  
  if (removedColumns.length) {
    updatedColumns = updatedColumns.filter(
      col => !removedColumns.some(removedCol => removedCol.key === col.key)
    );
  }

  return {
    ...storedValue,
    columns: updatedColumns
  };
};

const clearPrefixedStorage = (prefixKey: string): void => {
  if (!prefixKey) return;

  Object.keys(localStorage)
    .filter(key => key.startsWith(prefixKey))
    .forEach(key => localStorage.removeItem(key));
};

const processStoredValue = <T>(
  parsedValue: T, 
  initialValue: T | undefined
): T => {
  if (!hasColumns(parsedValue) || !hasColumns(initialValue)) {
    return parsedValue;
  }

  const columnChanges = compareColumns(initialValue.columns, parsedValue.columns);
  return updateColumns(parsedValue, columnChanges) as T;
};

export const useLocalStorage = <T>(
  prefixKey: string,
  key: string | null,
  initialValue?: T
): ReturnType<T> => {
  const storageKey = [prefixKey, key].filter(Boolean).join('_');

  const getInitialState = (): T => {
    try {
      const storedValue = localStorage.getItem(storageKey);
      
      if (!storedValue) {
        clearPrefixedStorage(prefixKey);
        return initialValue as T;
      }

      const parsedValue = JSON.parse(storedValue);
      return processStoredValue(parsedValue, initialValue);
    } catch (error) {
      console.error('Error reading from localStorage:', error);
      return initialValue as T;
    }
  };

  const [state, setState] = useState<T>(getInitialState);

  useEffect(() => {
    if (state === undefined) return;

    try {
      localStorage.setItem(storageKey, JSON.stringify(state));
    } catch (error) {
      console.error('Error writing to localStorage:', error);
    }
  }, [state, storageKey]);

  return [state, setState, { storageKey }];
};