import { AppPermissions } from "@model/app";
import { UserRules } from "@models/model.contact";
import { lazy } from "react";

export type route = {
  path: string;
  component(component: any): any;
  permission: string[];
  extraRules?: string[];
  customLayout: string;
};

export const routes: route[] = [
  {
    path: "/dashboard",
    component: lazy(() => import("@views/pages/dashboard/show/dashboard.show")),
    permission: AppPermissions.dashboardManagement,
    customLayout: "default"
  },
  {
    path: "/contacts",
    component: lazy(() => import("@views/pages/contacts/listings/contact.listing")),
    permission: AppPermissions.contactManagement,
    customLayout: "default"
  },
  {
    path: "/subscribers",
    component: lazy(() => import("@views/pages/subscribers/listings/subscriber.listing")),
    permission: AppPermissions.admin,
    customLayout: "default"
  },
  {
    path: "/contacts/:id",
    component: lazy(() => import("@views/pages/contacts/show/contact.show")),
    permission: AppPermissions.contactManagement,
    customLayout: "default"
  },
  {
    path: "/properties",
    component: lazy(() => import("@views/pages/properties/listings/my.listing")),
    permission: AppPermissions.propertyManagement,
    customLayout: "default"
  },
  {
    path: "/properties/:id",
    component: lazy(() => import("@views/pages/properties/show/property.show")),
    permission: AppPermissions.propertyManagement,
    customLayout: "default"
  },
  {
    path: "/team-listings",
    component: lazy(() => import("@views/pages/properties/listings/team.listing")),
    permission: ["super-admin", "admin", "agent", "user", "customer-success"],
    customLayout: "default"
  },
  {
    path: "/co-broke-listings",
    component: lazy(() => import("@views/pages/properties/listings/co-broke.listing")),
    permission: ["super-admin", "admin", "agent", "user", "customer-success"],
    customLayout: "default"
  },
  // {
  //   path: '/sellers-landlords',
  //   component: lazy(() => import('@views/pages/properties/sellers-landlords/sellers-landlords.index')),
  //   permission: ['*'],
  //   customLayout: 'default'
  // },
  {
    path: "/profile",
    component: lazy(() => import("@views/pages/settings/profile/profile.show")),
    permission: AppPermissions.profileSetting,
    customLayout: "default"
  },
  {
    path: "/preferences",
    component: lazy(() => import("@views/pages/settings/setting.preferences")),
    permission: AppPermissions.preferenceSetting,
    customLayout: "default"
  },
  {
    path: "/email-templates",
    component: lazy(() => import("@views/pages/settings/email-templates/show/email-template.show")),
    permission: AppPermissions.emailTemplateManagement,
    customLayout: "default"
  },
  {
    path: "/seller-deals",
    component: lazy(() => import("@views/pages/deals/listings/deals.index")),
    permission: AppPermissions.dealManagement,
    customLayout: "default"
  },
  {
    path: "/deals",
    component: lazy(() => import("@views/pages/deals/listings/deals.index")),
    permission: AppPermissions.dealManagement,
    customLayout: "default"
  },
  {
    path: "/leads",
    component: lazy(() => import("@views/pages/deals/listings/deals.index")),
    permission: AppPermissions.dealManagement,
    customLayout: "default"
  },
  {
    path: "/commission-deals",
    component: lazy(() => import("@views/pages/deals/listings/deals.index")),
    permission: AppPermissions.dealManagement,
    customLayout: "default"
  },
  {
    path: "/viewing-deals",
    component: lazy(() => import("@views/pages/deals/listings/deals.index")),
    permission: AppPermissions.dealManagement,
    customLayout: "default"
  },
  {
    path: "/viewing-deals/:id",
    component: lazy(() => import("@views/pages/deals/listings/deals.index")),
    permission: AppPermissions.dealManagement,
    customLayout: "default"
  },
  {
    path: "/developer-deals",
    component: lazy(() => import("@views/pages/deals/listings/deals.index")),
    permission: AppPermissions.dealManagement,
    customLayout: "default"
  },
  {
    path: "/referred-leads",
    component: lazy(() => import("@views/pages/deals/listings/deals.index")),
    permission: AppPermissions.dealManagement,
    customLayout: "default"
  },
  {
    path: "/dubai-instructions",
    component: lazy(() => import("@views/pages/deals/listings/deals.index")),
    permission: AppPermissions.dealManagement,
    customLayout: "default"
  },
  // {
  //   path: '/deals-configures',
  //   component: lazy(() => import('@views/pages/deals/deals.configures')),
  //   permission: ['admin'],
  //   customLayout: 'default'
  // },
  {
    path: "/developers",
    component: lazy(() => import("@views/pages/developers/listings/developers.listing")),
    permission: AppPermissions.developerManagement,
    customLayout: "default"
  },
  {
    path: "/developers/:id",
    component: lazy(() => import("@views/pages/developers/show/developer.show")),
    permission: AppPermissions.developerDetailManagement,
    extraRules: [UserRules.listingAdmin],
    customLayout: "default"
  },
  {
    path: "/projects",
    component: lazy(() => import("@views/pages/projects/listings/project.listing")),
    permission: AppPermissions.projectManagement,
    customLayout: "default"
  },
  {
    path: "/projects/:id",
    component: lazy(() => import("@views/pages/projects/show/project.show")),
    permission: AppPermissions.projectDetailManagement,
    extraRules: [UserRules.listingAdmin],
    customLayout: "default"
  },
  {
    path: "/deals/:id",
    component: lazy(() => import("@views/pages/deals/show/deal.show")),
    permission: AppPermissions.dealManagement,
    customLayout: "default"
  },
  {
    path: "/tasks",
    component: lazy(() => import("@views/pages/tasks/show/tasks.index")),
    permission: AppPermissions.taskManagement,
    customLayout: "default"
  },
  {
    path: "/tasks/:id",
    component: lazy(() => import("@views/pages/tasks/show/tasks.index")),
    permission: AppPermissions.taskManagement,
    customLayout: "default"
  },
  {
    path: "/blog/articles",
    component: lazy(() => import("@views/pages/articles/listings/articles.index")),
    permission: AppPermissions.blogManagement,
    customLayout: "default"
  },
  {
    path: "/blog/articles/:id",
    component: lazy(() => import("@views/pages/articles/show/articles.show")),
    permission: AppPermissions.blogManagement,
    customLayout: "default"
  },
  {
    path: "/blog/categories",
    component: lazy(
      () => import("@views/pages/settings/blog-categories/listings/categories.index")
    ),
    permission: AppPermissions.blogManagement,
    customLayout: "default"
  },
  {
    path: "/agent-centre",
    component: lazy(() => import("@views/pages/storage/listings/listing.storage")),
    permission: AppPermissions.storageManagement,
    customLayout: "default"
  },
  {
    path: "/geo/countries",
    component: lazy(() => import("@views/pages/geo/countries/listings/countries.index")),
    permission: AppPermissions.geoView,
    customLayout: "default"
  },
  {
    path: "/geo/countries/:country_id",
    component: lazy(() => import("@views/pages/geo/countries/show/countries.show")),
    permission: AppPermissions.geoView,
    customLayout: "default"
  },
  {
    path: "/geo/regions",
    component: lazy(() => import("@views/pages/geo/regions/listings/regions.index")),
    permission: AppPermissions.geoView,
    customLayout: "default"
  },
  {
    path: "/geo/regions/:region_id",
    component: lazy(() => import("@views/pages/geo/regions/show/regions.show")),
    permission: AppPermissions.geoView,
    customLayout: "default"
  },
  {
    path: "/geo/cities",
    component: lazy(() => import("@views/pages/geo/cities/listings/cities.index")),
    permission: AppPermissions.geoView,
    customLayout: "default"
  },
  {
    path: "/geo/cities/:city_id",
    component: lazy(() => import("@views/pages/geo/cities/show/cities.show")),
    permission: AppPermissions.geoView,
    customLayout: "default"
  },
  {
    path: "/geo/districts",
    component: lazy(() => import("@views/pages/geo/districts/listings/districts.index")),
    permission: AppPermissions.geoView,
    customLayout: "default"
  },
  {
    path: "/geo/districts/:district_id",
    component: lazy(() => import("@views/pages/geo/districts/show/districts.show")),
    permission: AppPermissions.geoView,
    customLayout: "default"
  },
  {
    path: "/geo/sub-districts",
    component: lazy(() => import("@views/pages/geo/sub-districts/listings/sub-districts.index")),
    permission: AppPermissions.geoView,
    customLayout: "default"
  },
  {
    path: "/geo/sub-districts/:sub_district_id",
    component: lazy(() => import("@views/pages/geo/sub-districts/show/sub-districts.show")),
    permission: AppPermissions.geoView,
    customLayout: "default"
  },
  {
    path: "/geo/places",
    component: lazy(() => import("@views/pages/geo/places/listings/places.index")),
    permission: AppPermissions.geoView,
    customLayout: "default"
  },
  {
    path: "/geo/places/:place_id",
    component: lazy(() => import("@views/pages/geo/places/show/places.show")),
    permission: AppPermissions.geoView,
    customLayout: "default"
  },
  {
    path: "/geo/place-categories",
    component: lazy(
      () => import("@views/pages/geo/place-categories/listings/place-categories.index")
    ),
    permission: AppPermissions.geoView,
    customLayout: "default"
  },
  {
    path: "/geo/place-categories/:category_id",
    component: lazy(() => import("@views/pages/geo/place-categories/show/place-categories.show")),
    permission: AppPermissions.geoView,
    customLayout: "default"
  }
];
