import { useState } from "react";

import {
  InputFilter,
  AutocompleteFilter,
  SelectionFilter,
  RangeFilter,
  AsyncAutoComplete,
  LocationGroup,
  PropertyFeatureGroup,
  ToggleFilter
} from "./filters/filters.index";
import { ColumnConfigType, QueryConfigType, ColumnFilterType } from "./index";
import MultipleAsyncAutoComplete from "./filters/filter.multi-async-select";
import CustomButton from "../buttons/button.custom";
import SiteSelectionFilter from "@components/data-table/filters/filter.site-selection";
import MultiSelectionFilter from "./filters/filter.multi-selection";
import SelectionInputOptionFilter from "@components/data-table/filters/filter.selection-input-option";

export interface IFilterProps {
  id: string;
  value: any;
  config: ColumnFilterType;
  inputType?: string;
  setFilterState: (prev: {}) => void;
  onUpdateFilterState?: (filter: {}) => void;
  setValue?: (obj: { name: string; value: any }) => void;
}

interface IProps {
  filters: ColumnConfigType[];
  queries: QueryConfigType;
  setQueries: (queries: QueryConfigType) => void;
  onApplyFilters?: () => void;
}

export default function TableFilters({ filters, queries, setQueries, onApplyFilters }: IProps) {
  const [filterState, setFilterState] = useState<object>(queries.filters || {});

  function handleApplyFilters() {
    // add app_preferences into Storage
    // , app_preferences: { ...preferences }
    const filters: any = { ...filterState };

    Object.keys(filters).forEach((k) => {
      if (!filters[k]) delete filters[k];
    });

    // reset page to 1 when filters applied
    setQueries({ ...queries, page: 1, filters });
    onApplyFilters && onApplyFilters();
  }

  function handleClearFilters() {
    setFilterState({});
  }

  return (
    <div className="text-[10px] flex flex-col h-full relative">
      <ul className="grow overflow-y-auto pb-[30px] mb-12">
        {filters
          .filter((column) => column.filters && !!column.filters.type)
          .map((column) => (
            <li key={column.key}>
              <div className="flex flex-col px-2 py-1 text-gray-500 sm:px-6">
                <div className="flex space-x-1 ">
                  <label
                    htmlFor={column.key}
                    className="relative block mb-0 font-medium text-gray-700 uppercase"
                  >
                    {column.label || column.key}
                  </label>
                  {filterState[column.key as keyof {}] && (
                    <div className="w-1 h-1 bg-red-400 rounded-full"></div>
                  )}
                </div>
                <div className="w-full">
                  {column.filters?.type &&
                  ["text", "email", "password", "number"].includes(column.filters.type) ? (
                    <InputFilter
                      id={column.key}
                      config={column.filters}
                      inputType={column.filters.type}
                      setFilterState={setFilterState}
                      value={filterState[column.key as keyof {}] || ""}
                    />
                  ) : column.filters?.type && ["autocomplete"].includes(column.filters?.type) ? (
                    <AutocompleteFilter
                      id={column.key}
                      config={column.filters}
                      setFilterState={setFilterState}
                      value={filterState[column.key as keyof {}] || ""}
                    />
                  ) : column.filters?.type &&
                    ["range", "calendar"].includes(column.filters?.type) ? (
                    <RangeFilter
                      id={column.key}
                      config={column.filters}
                      setFilterState={setFilterState}
                      value={filterState[column.key as keyof {}] || ""}
                    />
                  ) : column.filters?.type && ["selection"].includes(column.filters?.type) ? (
                    <SelectionFilter
                      id={column.key}
                      config={column.filters}
                      setFilterState={setFilterState}
                      value={filterState[column.key as keyof {}] || ""}
                    />
                  ) : column.filters?.type &&
                    ["selection-input-option"].includes(column.filters?.type) ? (
                    <SelectionInputOptionFilter
                      id={column.key}
                      config={column.filters}
                      setFilterState={setFilterState}
                      value={filterState[column.key as keyof {}] || ""}
                    />
                  ) : column.filters?.type && ["multi-selection"].includes(column.filters?.type) ? (
                    <MultiSelectionFilter
                      id={column.key}
                      config={column.filters}
                      setFilterState={setFilterState}
                      value={filterState[column.key as keyof {}] || ""}
                    />
                  ) : column.filters?.type && ["site-selection"].includes(column.filters?.type) ? (
                    <SiteSelectionFilter
                      id={column.key}
                      config={column.filters}
                      setFilterState={setFilterState}
                      value={filterState[column.key as keyof {}] || ""}
                    />
                  ) : column.filters?.type && ["asyncSelect"].includes(column.filters?.type) ? (
                    <AsyncAutoComplete
                      id={column.key}
                      config={column.filters}
                      setFilterState={setFilterState}
                      value={filterState[column.key as keyof {}] || ""}
                    />
                  ) : column.filters?.type &&
                    ["multipleAsyncSelect"].includes(column.filters?.type) ? (
                    <MultipleAsyncAutoComplete
                      id={column.key}
                      config={column.filters}
                      setFilterState={setFilterState}
                      value={filterState[column.key as keyof {}] || ""}
                    />
                  ) : column.filters?.type && ["locationGroup"].includes(column.filters?.type) ? (
                    <LocationGroup
                      id={column.key}
                      config={column.filters}
                      setFilterState={setFilterState}
                      value={filterState[column.key as keyof {}] || ""}
                      filterState={filterState}
                    />
                  ) : column.filters?.type &&
                    ["propertyFeatureGroup"].includes(column.filters?.type) ? (
                    <PropertyFeatureGroup
                      id={column.key}
                      config={column.filters}
                      setFilterState={setFilterState}
                      value={filterState[column.key as keyof {}] || ""}
                      filterState={filterState}
                    />
                  ) : column.filters?.type && ["toggle"].includes(column.filters?.type) ? (
                    <ToggleFilter
                      id={column.key}
                      config={column.filters}
                      setFilterState={setFilterState}
                      value={filterState[column.key as keyof {}] || ""}
                    />
                  ) : null}
                </div>
              </div>
            </li>
          ))}
      </ul>
      <div className="absolute bottom-0 left-0 right-0 flex items-center justify-end p-4 bg-white border-t space-x-7">
        {!!Object.keys(filterState).length && (
          <p
            className="text-sm text-red-300 underline cursor-pointer hover:text-red-500"
            onClick={handleClearFilters}
          >
            Clear Filters
          </p>
        )}
        <CustomButton
          className="h-[34px]"
          label="Apply Filters"
          variant="primary"
          onClick={handleApplyFilters}
        />
      </div>
    </div>
  );
}
