export { default as SearchSelect } from "./searchSelect";

export enum SearchOptionKey {
  users = "users",
  agents = "agents",
  teams = "teams",
  internalUsers = "internal-users",
  developer = "developer",
  projects = "projects",
  regions = "regions",
  cities = "cities",
  districts = "districts",
  sub_districts = "sub_districts"
}
