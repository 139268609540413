import axios from "axios";
import { appConfig } from "./app.config";
import { transformQueryString, getTimezoneOffset, getCountryTimezone } from "@utils/helpers";
import { AppStorageType, CountryCode } from "@model/app";

export type queryType =
  | {
      per_page?: number;
      offset?: number;
      page?: number;
      filters?: string;
      ref_type?: string;
      ref_id?: string;
      type?: string;
      search?: string;
      language_id?: string;
      country_code?: CountryCode;
      timezone_offset: number;
      model_id?: string;
      model_type?: string;
      view_user_id?: number;
    }
  | any;

// need timezone for all requests to determine on server side

const getQueries = (queries: queryType): queryType => {
  const item = localStorage.getItem("app") || "{}";
  const app: AppStorageType = JSON.parse(item);
  const timeZone = getCountryTimezone(app.preferences?.defaultCountry);
  const TIMEZONE_OFFSET = getTimezoneOffset(timeZone);
  return {
    ...queries,
    timezone_offset: TIMEZONE_OFFSET
  };
};

export const getUrl = ({
  path,
  queries,
  isApi
}: {
  path: string;
  queries: queryType;
  isApi: boolean;
}) => {
  // if (!queryString) queryString = {}
  // queryString.mode = process.env.NODE_ENV
  let queryString = transformQueryString(queries);
  const endPoint = isApi ? appConfig.apiEndPoint : appConfig.domain;
  return `${endPoint}/${path}${queryString}`;
};

export const wrapPromise = (promise: Promise<any>) => {
  let status: string = "pending";
  let result: any;
  let suspender = promise.then(
    (r) => {
      status = "success";
      result = r;
    },
    (e) => {
      status = "error";
      result = e;
    }
  );
  // console.log(status)
  return {
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    }
  };
};
// import memoize from "memoize-one";
// import { appConfig } from './config'
// import { transformQueryString} from './utils'

// export const getUrl = ({ path, queryString, isApi }) => {
//   if (!queryString) queryString = {}
//   queryString.mode = process.env.NODE_ENV
//   let textStr = ''
//   if (isApi) {
//     textStr = transformQueryString(queryString)
//   }
//   const endPoint = (isApi) ? appConfig.apiEndPoint : appConfig.domain
//   return `${endPoint}/${path}${textStr}`
// }

const handleError = (err: any, reject: any) => reject(err);

const createHeader = async (header = {}) => {
  const appStorage: { token: string } = JSON.parse(localStorage.getItem("app") || "");
  let defaultHeader = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${appStorage?.token || ""}`
  };

  // headers.token = 'bearer test'
  const instance = axios.create({
    headers: { ...defaultHeader, ...header }
  });
  return instance;
};

export const getBlob = async (
  { path, queries, externalUrl }: { path: string; queries: queryType; externalUrl?: string },
  isApi = true
) => {
  const instance = await createHeader();
  const endpoint: string = externalUrl || getUrl({ path, queries, isApi });
  return new Promise<any>((resolve, reject) => {
    instance
      .get(endpoint, {
        responseType: "blob"
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        handleError(err, reject);
      });
  });
};

export const get = async (
  { path, queries }: { path: string; queries: queryType },
  isApi = true
) => {
  const instance = await createHeader();
  return new Promise<any>((resolve, reject) => {
    instance
      .get(getUrl({ path, queries: getQueries(queries), isApi }))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        handleError(err, reject);
      });
  });
};

export const post = async (
  { path, data, config }: { path: string; data: any; config?: any },
  isApi = true
) => {
  const instance = await createHeader();
  return new Promise((resolve, reject) => {
    instance
      .post(getUrl({ path, queries: {}, isApi }), data, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        handleError(err, reject);
      });
  });
};

export const put = async ({ path, data }: { path: string; data: any }, isApi = true) => {
  const instance = await createHeader();
  return new Promise((resolve, reject) => {
    instance
      .put(getUrl({ path, queries: {}, isApi }), data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        handleError(err, reject);
      });
  });
};

export const patch = async ({ path, data }: { path: string; data: any }, isApi = true) => {
  const instance = await createHeader();
  return new Promise((resolve, reject) => {
    instance
      .patch(getUrl({ path, queries: {}, isApi }), data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        handleError(err, reject);
      });
  });
};

export const deleteItem = async (
  { path, queries }: { path: string; queries: any },
  isApi = true
) => {
  const instance = await createHeader();
  return new Promise((resolve, reject) => {
    instance
      .delete(getUrl({ path, queries: getQueries(queries), isApi }))
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        handleError(err, reject);
      });
  });
};

export const uploadFiles = async ({ path, data }: { path: string; data: any }, isApi = true) => {
  const instance = await createHeader({ "Content-Type": "multipart/form-data" });

  return new Promise((resolve, reject) => {
    instance
      .post(getUrl({ path, queries: {}, isApi }), data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        handleError(err, reject);
      });
  });
};
