import { get } from "@utils/api";
import type { queryType } from "@utils/api";

class ProjectsNameDropdownService {
  endpoint: string;
  queries: queryType;

  static async call(value: string, configs: any): Promise<any> {
    const queries: queryType = {
      search: value,
      language_id: "en",
      country_code: configs?.filters?.country_code || ""
    };
    // console.log(configs)
    return await new ProjectsNameDropdownService("projects/names", queries).getProjects(configs);
  }

  constructor(endpoint: string, queries: queryType) {
    this.endpoint = endpoint;
    this.queries = queries;
  }

  async getProjects(configs: any): Promise<any> {
    const path = this.endpoint;
    const queries = this.queries;
    const { data } = await get({ path, queries });

    if (!data.length) {
      if (configs?.showCreateNew) return [{ value: "new", label: "Other building name" }];

      return [];
    }

    return data.map((item: any) => ({ label: item.content, value: item.ref_id }));
  }
}

export default ProjectsNameDropdownService;
