import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, useJsApiLoader, Autocomplete, MarkerF } from "@react-google-maps/api";
import ReactDOM from "react-dom";
import { countriesAvailable, CountryCode } from "@model/app";
import { isProduction } from "@utils/helpers";

const googleMapsApiKey = isProduction()
  ? "AIzaSyBnoK0xak3G3Tnr-XeMup0Xjpb9AKMYsYA&"
  : "AIzaSyCrr0fYPsWmzVMBDkmJMqDuy6jHXr6VzPE&";

type Libraries = ("drawing" | "geometry" | "localContext" | "places" | "visualization")[];

const mapLibraries: Libraries = ["places"];
interface IProps {
  lat: string | null;
  lng: string | null;
  country_code: CountryCode;
  placeDefaultValue: string;
  onUpdate: (mapState: any) => void;
  handlePlacesChanged: (place: any) => void;
}

export default function GoogleMapComponent({
  lat,
  lng,
  country_code,
  onUpdate,
  handlePlacesChanged,
  placeDefaultValue
}: IProps) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey,
    libraries: mapLibraries
  });

  const COUNTRY = countriesAvailable.find((c) => c.country_code === country_code);
  const defaultGps = {
    lat: lat ? parseFloat(lat) : COUNTRY?.gps.lat || 13.7244416,
    lng: lng ? parseFloat(lng) : COUNTRY?.gps.lng || 100.3529157
  };

  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const markerRef = useRef<google.maps.Marker | null>(null);
  const draggingRef = useRef<boolean>(false);
  const eventRef = useRef(false);
  const [mapState, setMapState] = useState<{
    draggable: boolean;
    center: any;
    lat: number;
    lng: number;
    zoom: number;
    mapTypeId: string;
  }>({
    draggable: true,
    center: { lat: defaultGps.lat, lng: defaultGps.lng },
    lat: defaultGps.lat,
    lng: defaultGps.lng,
    zoom: 13,
    mapTypeId: "satellite"
  });

  const handleMarkerDragStart = () => {
    // Disable the default drag behavior of the map
    map?.setOptions({ draggable: false });
    draggingRef.current = true;
  };

  const handleMarkerDragEnd = (event: google.maps.MapMouseEvent) => {
    // Enable the default drag behavior of the map
    map?.setOptions({ draggable: true });
    draggingRef.current = false;
    eventRef.current = true;
    handleDragMarkerEvent(event);
  };

  useEffect(() => {
    if (isLoaded && map && !markerRef.current) {
      google.maps.event.addListener(map, "drag", function (event: google.maps.MapMouseEvent) {
        if (draggingRef.current) {
          // Prevent the map from scrolling during marker drag
          map.panTo(markerRef.current?.getPosition()!);
          handleDragMarkerEvent(event);
        }
      });
    }
  }, [isLoaded, map, mapState.center]);

  const handleDragMarkerEvent = (event: google.maps.MapMouseEvent) => {
    const lat = event?.latLng?.lat() || 0;
    const lng = event?.latLng?.lng() || 0;
    setMapState((prev) => ({ ...prev, center: { lat, lng }, lat, lng }));
  };

  const handleMarkerOnLoad = (markerInstance: google.maps.Marker) => {
    markerRef.current = markerInstance;
  };

  const onMapLoad = React.useCallback(function callback(map: google.maps.Map) {
    map.setZoom(mapState.zoom);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);

  const onCustomChange = (e: React.FormEvent<HTMLInputElement>) => {
    eventRef.current = true;
    let customGps = { lat: mapState.lat, lng: mapState.lng };

    const { value, name } = e.target as HTMLInputElement;
    type Keys = keyof typeof customGps;
    customGps[name as Keys] = value ? parseFloat(value) : 0;

    const { lat, lng } = customGps;
    setMapState((prev) => ({ ...prev, center: { lat, lng }, lat, lng }));
  };

  const onPlacesChanged = (place: any) => {
    if (place) {
      const geometry = place?.geometry;
      if (geometry) {
        const location = geometry?.location;
        if (location) {
          const lat = location.lat();
          const lng = location.lng();
          setMapState((prev) => ({ ...prev, center: { lat, lng }, lat, lng }));
        }
      }
    }
    handlePlacesChanged(place);
  };

  useEffect(() => {
    eventRef.current = false;
    setMapState({
      draggable: true,
      center: { lat: defaultGps.lat, lng: defaultGps.lng },
      lat: defaultGps.lat,
      lng: defaultGps.lng,
      zoom: 13,
      mapTypeId: "satellite"
    });
  }, [lat, lng]);

  useEffect(() => {
    if (mapState.lat && mapState.lng) {
      markerRef.current?.setPosition(new google.maps.LatLng(+mapState.lat, +mapState.lng));
    }
    if (!mapState.draggable || !eventRef.current) return;
    onUpdate(mapState);
  }, [mapState.lat, mapState.lng]);

  return isLoaded ? (
    // Important! Always set the container height explicitly
    <div>
      {map && (
        <SearchBox
          country_code={country_code}
          mapsapi={map}
          onPlacesChanged={onPlacesChanged}
          defaultValue={placeDefaultValue}
        />
      )}

      <div className="flex flex-col mt-3 sm:flex-row sm:space-x-4">
        <div className="flex items-center flex-1 space-x-2">
          <label className="w-[80px] sm:w-auto" htmlFor="custom-lat">
            Latitude
          </label>
          <input
            type="text"
            id="custom-lat"
            className="w-full pb-1 border-0 border-b border-gray-200 outline-none focus:border-purple-400 ring-0 sm:text-sm focus:ring-0"
            name="lat"
            value={mapState.lat}
            onChange={onCustomChange}
          />
        </div>
        <div className="flex items-center flex-1 space-x-2">
          <label className="w-[80px] sm:w-auto" htmlFor="custom-lng">
            Longitude
          </label>
          <input
            type="text"
            id="custom-lng"
            className="w-full pb-1 border-0 border-b border-gray-200 outline-none focus:border-purple-400 ring-0 sm:text-sm focus:ring-0"
            name="lng"
            value={mapState.lng}
            onChange={onCustomChange}
          />
        </div>
      </div>

      <div className="w-full mt-3 overflow-hidden h-[380px]">
        <GoogleMap
          mapContainerStyle={{
            height: "380px"
          }}
          center={mapState.center}
          zoom={mapState.zoom}
          onLoad={onMapLoad}
          onUnmount={onUnmount}
        >
          <MarkerF
            position={mapState.center}
            draggable={true}
            onLoad={handleMarkerOnLoad}
            onDragStart={handleMarkerDragStart}
            onDragEnd={handleMarkerDragEnd}
          />
        </GoogleMap>
      </div>
    </div>
  ) : (
    <></>
  );
}

interface ISearchBox {
  country_code: CountryCode;
  mapsapi: google.maps.Map;
  onPlacesChanged: any;
  defaultValue: any;
}
const SearchBox = (props: ISearchBox) => {
  const inputRef = useRef(null);
  let searchBox = useRef<google.maps.places.Autocomplete | null>(null);

  const [defaultValue, setDefaultValue] = useState(props.defaultValue);

  useEffect(() => {
    setDefaultValue(props.defaultValue);
  }, [props.defaultValue]);

  const onPlacesChanged = () => {
    if (searchBox.current) {
      props.onPlacesChanged(searchBox.current.getPlace());
    }
  };

  useEffect(() => {
    const input = ReactDOM.findDOMNode(inputRef.current) as HTMLInputElement;
    const options = {
      componentRestrictions: { country: props.country_code }
    };
    let searchBoxListener: google.maps.MapsEventListener | null = null;
    searchBox.current = new google.maps.places.Autocomplete(input, options);

    searchBoxListener = searchBox.current.addListener("place_changed", onPlacesChanged);
    return () => {
      if (searchBoxListener) google.maps.event.removeListener(searchBoxListener);
    };
  }, [props.country_code]);

  return (
    <div className="relative mt-1 ">
      <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="far"
          data-icon="location-pin"
          className="w-5 h-5 svg-inline--fa fa-location-pin"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
        >
          <path
            fill="currentColor"
            d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM320.7 249.2C331.5 223.6 336 204.4 336 192C336 112.5 271.5 48 192 48C112.5 48 48 112.5 48 192C48 204.4 52.49 223.6 63.3 249.2C73.78 274 88.66 301.4 105.8 329.1C134.2 375.3 167.2 419.1 192 451.7C216.8 419.1 249.8 375.3 278.2 329.1C295.3 301.4 310.2 274 320.7 249.2V249.2z"
          ></path>
        </svg>
      </div>
      <Autocomplete onPlaceChanged={onPlacesChanged}>
        <input
          ref={inputRef}
          defaultValue={defaultValue}
          placeholder="Start typing a place or address. You can then drag the pin on the map to adjust the property location."
          className="block w-full pl-6 h-[40px] border-0 border-b border-gray-200   outline-none ring-0 sm:text-sm"
        />
      </Autocomplete>
    </div>
  );
};
