import { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

import MobileNav from "./layout.default.mobile-nav";
import Logo from "@images/logo.jpg";

import {
  selectUserProfile,
  selectAdmin,
  selectIsSuperAdmin,
  selectViewUser,
  selectIsSystemSuperAdmin
} from "@state/selectors";
import { logout, updateViewUser } from "@state/actions/action.creators";

import { classNames, isProduction, updateLocalStorageSuperAdmin } from "@utils/helpers";
import { userNavigation } from "./layout.configs";
import { appConfig } from "@utils/app.config";
import { UserProfileImage } from "@views/components/users/user.profile-image";
import { NotificationMenu } from "@components/notification-controller";
import { getCustomSelectStyles } from "@utils/react-select.helpers";
import { SearchSelect } from "@components/forms/search-select";

const IS_PRODUCTION = isProduction();

export default function LayoutDefaultHeader() {
  const dispatch = useDispatch();

  const userData = useSelector(selectUserProfile);
  const isAdmin = useSelector(selectAdmin);
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const isSystemSuperAdmin = useSelector(selectIsSystemSuperAdmin);
  const viewUser = useSelector(selectViewUser);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const dropdownMenuClassNames = (active: boolean) => {
    return classNames("block px-4 py-2 text-sm text-gray-700", {
      "bg-gray-100": active
    });
  };

  return (
    <header className="relative flex items-center flex-shrink-0 h-16 bg-white">
      {/* Logo area */}
      <div className="absolute inset-y-0 flex items-center justify-start right-2 md:left-2 md:static md:flex-shrink-0">
        <a
          href={appConfig.websiteDomain}
          className="flex items-center justify-center w-[180px] h-auto focus:outline-none focus:ring-0 ml-3"
        >
          <img className="w-full h-full" src={Logo} alt="Workflow" />
        </a>
        {!IS_PRODUCTION && (
          <span className="ml-2 mt-[5px] inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-green-200 text-green-800">
            Staging
          </span>
        )}
      </div>

      {/* Menu button area */}
      <div className="absolute inset-y-0 left-0 flex items-center pr-4 sm:pr-6 md:hidden">
        {/* Mobile menu button */}
        <button
          type="button"
          className="inline-flex items-center justify-center p-2 -mr-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open main menu</span>
          <MenuIcon className="block w-6 h-6" aria-hidden="true" />
        </button>
      </div>

      {/* Desktop nav area */}
      <div className="hidden md:min-w-0 md:flex-1 md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          {/* <div className="relative max-w-2xl text-gray-400 focus-within:text-gray-500">
            <label htmlFor="desktop-search" className="sr-only">
              Search
            </label>
            <input
              id="desktop-search"
              type="search"
              placeholder="Search"
              className="block w-full pl-12 placeholder-gray-500 border-transparent focus:border-transparent sm:text-sm focus:ring-0"
            />
            <div className="absolute inset-y-0 left-0 flex items-center justify-center pl-4 pointer-events-none">
              <SearchIcon className="w-5 h-5" aria-hidden="true" />
            </div>
          </div> */}
        </div>
        <div className="flex items-center flex-shrink-0 pr-4 ml-10 space-x-10">
          {(isSuperAdmin || isSystemSuperAdmin) && (
            <div className="flex flex-col px-2 py-1 text-gray-500 sm:px-6 w-[15rem]">
              <div className="flex space-x-1 ">
                <label
                  htmlFor={"agent_id"}
                  className="relative block mb-0 font-medium text-gray-700 uppercase text-[0.5rem]"
                >
                  View Agents
                </label>
              </div>
              <div className="w-full relative z-[19]">
                <SearchSelect
                  configs={{
                    id: "agent_id",
                    key: "agents",
                    includedItem: false,
                    value: viewUser
                  }}
                  key={"agent_id"}
                  className="w-full text-[13px]"
                  styles={getCustomSelectStyles("", true)}
                  isClearable
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null
                  }}
                  value={viewUser}
                  noOptionsMessage={() => "Start typing"}
                  onChange={(e: any) => {
                    updateLocalStorageSuperAdmin({
                      view_user: e,
                      is_system_super_admin: isSystemSuperAdmin
                    });
                    updateViewUser(dispatch, e);
                  }}
                />
              </div>
            </div>
          )}
          <NotificationMenu />
          <div className="flex items-center space-x-4">
            {(isAdmin || isSuperAdmin) && (
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-purple-100 text-purple-800">
                {isSuperAdmin ? "Super Admin" : "Admin"}
              </span>
            )}
            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button className="flex text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600">
                <span className="sr-only">Open user menu</span>
                {userData && (
                  <UserProfileImage
                    user={userData}
                    image={userData.profile_image || null}
                    styles="w-10 h-10"
                  />
                )}
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-30 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <>
                          <Link to="/profile" className={dropdownMenuClassNames(active)}>
                            My Profile
                          </Link>
                          <button
                            onClick={() => logout(dispatch)}
                            className={`${dropdownMenuClassNames(active)} w-full text-left`}
                          >
                            Sign Out
                          </button>
                        </>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>

      {/* Mobile menu, show/hide this `div` based on menu open/closed state */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-40 md:hidden" onClose={setMobileMenuOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="sm:block sm:fixed sm:inset-0 sm:bg-gray-600 sm:bg-opacity-75" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-out duration-150 sm:ease-in-out sm:duration-300"
            enterFrom="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
            enterTo="transform opacity-100 scale-100  sm:translate-x-0 sm:scale-100 sm:opacity-100"
            leave="transition ease-in duration-150 sm:ease-in-out sm:duration-300"
            leaveFrom="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
            leaveTo="transform opacity-0 scale-110  sm:translate-x-full sm:scale-100 sm:opacity-100"
          >
            <nav
              className="fixed inset-0 z-40 w-full h-full overflow-auto bg-white sm:inset-y-0 sm:left-auto sm:right-0 sm:max-w-sm sm:w-full sm:shadow-lg"
              aria-label="Global"
            >
              <div className="flex items-center justify-between h-16 px-4 sm:px-6">
                <div className="flex min-w-[200px]">
                  <img className="block w-[90%] h-auto" src={Logo} alt="Workflow" />
                </div>
                <button
                  type="button"
                  className="inline-flex items-center justify-center p-2 -mr-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close main menu</span>
                  <XIcon className="block w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              {/* <div className="px-4 mx-auto mt-2 max-w-8xl sm:px-6">
                <div className="relative text-gray-400 focus-within:text-gray-500">
                  <label htmlFor="mobile-search" className="sr-only">
                    Search
                  </label>
                  <input
                    id="mobile-search"
                    type="search"
                    placeholder="Search"
                    className="block w-full pl-10 placeholder-gray-500 border-gray-300 rounded-md focus:border-purple-600 focus:ring-purple-600"
                  />
                  <div className="absolute inset-y-0 left-0 flex items-center justify-center pl-3">
                    <SearchIcon className="w-5 h-5" aria-hidden="true" />
                  </div>
                </div>
              </div> */}
              <div className="px-2 py-3 mx-auto space-y-4 max-w-8xl sm:px-4">
                <div className="flex items-center px-4 mx-auto max-w-8xl sm:px-6">
                  <div className="flex-shrink-0">
                    {userData && (
                      <UserProfileImage
                        user={userData}
                        image={userData.profile_image || null}
                        styles="w-10 h-10"
                      />
                    )}
                  </div>
                  <div className="flex-1 min-w-0 ml-3">
                    <div className="text-base font-medium text-gray-800 truncate">
                      {userData?.name}
                      {isSuperAdmin && " (Super Admin)"}
                    </div>
                    <div className="text-sm font-medium text-gray-500 truncate">
                      {userData?.email}
                    </div>
                  </div>
                  <NotificationMenu />
                </div>
                {(isSuperAdmin || isSystemSuperAdmin) && (
                  <div className="flex flex-col px-2 py-1 text-gray-500 sm:px-6 w-full">
                    <div className="flex space-x-1 ">
                      <label
                        htmlFor={"agent_id"}
                        className="relative block mb-0 font-medium text-gray-700 uppercase text-[0.5rem]"
                      >
                        View Agents
                      </label>
                    </div>
                    <div className="w-full relative z-[19]">
                      <SearchSelect
                        configs={{
                          id: "agent_id",
                          key: "agents",
                          includedItem: false,
                          value: viewUser
                        }}
                        key={"agent_id"}
                        className="w-full text-[13px]"
                        styles={getCustomSelectStyles("", true)}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null
                        }}
                        isClearable
                        value={viewUser}
                        noOptionsMessage={() => "Start typing"}
                        onChange={(e: any) => {
                          updateViewUser(dispatch, e);
                        }}
                      />
                    </div>
                  </div>
                )}
                <MobileNav role={userData?.role || ""} />
              </div>
              <div className="py-3 border-t border-gray-200">
                {/* <div className="flex items-center px-4 mx-auto max-w-8xl sm:px-6">
                  <div className="flex-shrink-0">
                    {userData && (
                      <UserProfileImage user={userData} image={userData.profile_image || null} styles="w-10 h-10" />
                    )}
                  </div>
                  <div className="flex-1 min-w-0 ml-3">
                    <div className="text-base font-medium text-gray-800 truncate">{userData?.name}</div>
                    <div className="text-sm font-medium text-gray-500 truncate">{userData?.email}</div>
                  </div>
                  <NotificationMenu />
                </div> */}
                <div className="px-2 mx-auto mt-3 space-y-1 max-w-8xl sm:px-4">
                  {userNavigation.map((item) =>
                    item.key === "sign_out" ? (
                      <button
                        key={item.key}
                        onClick={() => logout(dispatch)}
                        className="block w-full px-3 py-2 text-base font-medium text-left text-gray-900 rounded-md hover:bg-gray-50"
                      >
                        {item.name}
                      </button>
                    ) : (
                      <Link
                        key={item.key}
                        to={item.href}
                        className="block px-3 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-50"
                      >
                        {item.name}
                      </Link>
                    )
                  )}
                </div>
              </div>
            </nav>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </header>
  );
}
