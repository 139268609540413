import { IMedia } from "./model.media";
import { AppInputOptions, PreferenceType, UserRole } from "./model.app";
import { AdditionalContactType } from "./model.additional-contact";

export type ContactUserType = {
  id: number | null;
  name: string | null;
  email: string | null;
  phone: string | null;
};

export type ITeam = {
  id: number;
  name: string;
};

export type UserType = {
  id: number;
  name: string;
  email: string;
  contacts: AdditionalContactType[];
  company_name: string | null;
  agents: ContactUserType[];
  enquiry_email: string | null;
  phone: string;
  country_code: string;
  preferred_language: string | null;
  email_verified_at: string | null;
  role: UserRole;
  types: string;
  tags: string;
  is_active: boolean;
  is_previous_client: boolean;
  is_viewable?: boolean;
  is_editable?: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  property_contacts: any;
  position: string;
  line?: string;
  wechat?: string;
  linkedin?: string;
  country?: string;
  team_name?: string;
  ip_address?: string;
  first_deal_source?: string;
  first_deal_create?: string;
  last_deal_create?: string;
  first_won_deal_create?: string;
  last_won_deal_create?: string;
  first_lost_deal_create?: string;
  last_lost_deal_create?: string;
  created_by?: string;
  input_options?: Partial<AppInputOptions>;
  deals?: [];
  profile_image?: IMedia | null;
  preferences: PreferenceType | [];
  teams?: ITeam[];
  team_partners: string[];
  twilio_token?: string;
  twilio_caller_id?: string;
  user_partners: string[];
  user_rules: string[];
};

export const userTags = [
  { label: "Admin", value: "admin" },
  { label: "Buyer", value: "buyer" },
  { label: "Seller", value: "seller" },
  { label: "External Agent", value: "external-agent" },
  { label: "Akando", value: "Akando" },
  { label: "PROREAL", value: "ProReal" },
  { label: "HHPLA", value: "HHPLA" },
  { label: "BkkCondos", value: "BkkCondos" },
  { label: "SiamRealEstate", value: "SiamRealEstate" },
  { label: "MiniSite", value: "minisite" }
];

export const contactTypes = [
  { label: "Private Property Seller", value: "Private Property Seller" },
  { label: "Developer", value: "Developer" },
  { label: "Landlord (Owns property for Rent)", value: "Landlord (Owns property for Rent)" },
  { label: "Property Agent", value: "Property Agent" },
  { label: "Property Buyer", value: "Property Buyer" },
  { label: "Property Renter", value: "Property Renter" },
  { label: "Spam / Marketing", value: "Spam / Marketing" }
];

export const sellerContactTypes = [
  { label: "Residential - Sales", value: "Residential - Sales" },
  { label: "Residential - Leasing", value: "Residential - Leasing" },
  { label: "Commercial - Office", value: "Commercial - Office" },
  { label: "Commercial - Land", value: "Commercial - Land" },
  { label: "Commercial - Warehouse", value: "Commercial - Warehouse" },
  { label: "Commercial - Factory", value: "Commercial - Factory" },
  { label: "Commercial - Bar/Restaurant", value: "Commercial - Bar/Restaurant" },
  { label: "Commercial - Hotel", value: "Commercial - Hotel" }
];

export const UserRules = {
  hidePropertyOwnerAndNoteAndAdditionalContact:
    "hide_property_owner_and_note_and_additional_contact",
  listingAdmin: "listing_admin",
  taskManager: "task_manager"
};
