import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { ITask, TASK_TYPE } from "@model/task";
import { FormGroup, MyEditor } from "@views/components";
import { SearchSelect, SearchOptionKey } from "@views/components/forms/search-select";
import { selectAdmin, selectDefaultCountry, selectTaskManager } from "@state/selectors";
import { uploadFiles } from "@utils/api";
import { IMedia } from "@model/media";
import { IProperty } from "@model/property";
import { IProject } from "@model/project";
import { classNames, getGMTOffset, getTimeZoneMoment } from "@utils/helpers";
import { countriesAvailable } from "@model/app";
import { useTaskStore } from "./store";
import { useMemo } from "react";
import { ArrowUpRightFromSquareIcon } from "../icon";
import { getCustomSelectStyles } from "@utils/react-select.helpers";
import moment from "moment";

const schemaObj = {
  notes: { description: yup.string().required("Required field") },
  task: {
    title: yup.string().required("Required field"),
    scheduled_at: yup.string().required("Required field"),
    agent_id: yup.number().nullable().required("Required field")
  },
  viewing: {
    title: yup.string().required("Required field"),
    scheduled_at: yup.string().required("Required field"),
    duration: yup.number().nullable(),
    agent_id: yup.number().nullable().required("Required field"),
    user_id: yup.number().nullable().required("Required field"),
    deal_id: yup.number().nullable().required("Required field")
  },
  deal: {}
};
const notesSchema = yup.object(schemaObj.notes).required();
const tasksSchema = yup.object(schemaObj.task).required();
const viewingsSchema = yup.object(schemaObj.viewing).required();

type ConfigType = {
  propertyOptions: IProperty[];
  projectOptions?: IProject[];
};
interface IProps {
  configs?: ConfigType;
  type: TASK_TYPE;
  data: ITask;
  handleSubmit: (data: ITask) => void;
  handleCancel: () => void;
}

export default function MainForm({ type, data, configs, ...args }: IProps) {
  const isAdmin = useSelector(selectAdmin);
  const isTaskManager = useSelector(selectTaskManager);
  const defaultCountryCode = useSelector(selectDefaultCountry);
  const hasPermission = isAdmin || isTaskManager;
  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ITask>({
    defaultValues: {
      id: data.id,
      title: data?.title || "",
      type: data.type,
      scheduled_at: data.scheduled_at || "",
      duration: data.duration,
      agent_id: data.agent_id || null,
      property_ids: data.properties?.map((i: IProperty) => i.id),
      project_ids: data.projects?.map((i: IProject) => i.id),
      user_id: data.user_id || null,
      deal_id: data.deal_id || null,
      country_code: defaultCountryCode,
      description: data?.description || ""
    },
    resolver: yupResolver(
      data.type === TASK_TYPE.NOTES
        ? notesSchema
        : data.type === TASK_TYPE.VIEWING
        ? viewingsSchema
        : tasksSchema
    )
  });

  const isEditable = data.id !== "new" && !data.is_editable;
  const isNotes = type === TASK_TYPE.NOTES;
  const defaultCountry = useMemo(() => {
    return countriesAvailable.find((i) => i.country_code === defaultCountryCode);
  }, [defaultCountryCode]);
  const gmtOffset = getGMTOffset(defaultCountry?.timeZone);

  const inAction = useTaskStore((state) => state.inAction);

  const durationOptions = useMemo(() => {
    return Array.from({ length: 16 }, (_, i) => {
      const minutes = (i + 1) * 30;
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      const durationDate = new Date(0, 0, 0, hours, remainingMinutes);

      return {
        value: minutes,
        label: moment(durationDate).format("HH:mm")
      };
    });
  }, []);

  return (
    <div className="w-full sm:w-[600px]">
      <form onSubmit={handleSubmit(args.handleSubmit)}>
        <div className="relative text-sm ">
          {!isNotes ? (
            <>
              <FormGroup
                labelWidthClass="sm:w-[150px]"
                required={Object.keys(schemaObj[data.type]).includes("title")}
                label="Title"
                errors={errors}
                field="title"
              >
                <input
                  type="text"
                  {...register("title", { required: true })}
                  className="h-[40px] block w-full border-gray-300 rounded-md shadow-sm focus:ring-0  sm:text-sm focus:border-purple-700"
                />
              </FormGroup>
              <FormGroup
                labelWidthClass="sm:w-[150px]"
                required={Object.keys(schemaObj[data.type]).includes("scheduled_at")}
                label="Due date"
                errors={errors}
                field="scheduled_at"
              >
                <Controller
                  name="scheduled_at"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <>
                        <span className="inline-flex items-center min-w-2/5 h-[40px] lg:whitespace-nowrap sm:whitespace-normal px-3 text-gray-500 border border-r-0 border-gray-300 rounded-l-md bg-gray-50 sm:text-sm">
                          {defaultCountry?.timeZone} {gmtOffset}
                        </span>
                        <input
                          type="datetime-local"
                          onChange={(e) => {
                            // convert back to UTC
                            onChange(getTimeZoneMoment(e.target.value).utc().format());
                          }}
                          defaultValue={
                            value ? getTimeZoneMoment(value).format("YYYY-MM-DDTHH:mm:ss") : ""
                          }
                          className="h-[40px] block w-full border-gray-300 rounded-md rounded-l-none shadow-sm focus:ring-0  sm:text-sm focus:border-purple-700"
                        />
                      </>
                    );
                  }}
                />
              </FormGroup>

              {type === TASK_TYPE.VIEWING && (
                <FormGroup
                  label="Duration"
                  labelWidthClass="sm:w-[150px]"
                  errors={errors}
                  field="duration"
                >
                  <Controller
                    key="duration_react_selection"
                    name="duration"
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                      <Select
                        placeholder="Select duration (HH:mm)"
                        options={durationOptions}
                        className="w-full"
                        defaultValue={durationOptions.find((i) => i.value === value)}
                        onChange={(opt: any) => onChange(opt?.value || null)}
                        styles={getCustomSelectStyles(value)}
                        menuPortalTarget={document.body}
                        isClearable
                      />
                    )}
                  />
                </FormGroup>
              )}

              <FormGroup
                labelWidthClass="sm:w-[150px]"
                required={Object.keys(schemaObj[data.type]).includes("deal_id")}
                label="Deal"
                errors={errors}
                field="deal_id"
                endIcon={data?.deal ? openLinkIcon(`/deals/${data?.deal?.id}`) : undefined}
              >
                <Controller
                  name="deal_id"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    let defaultValue = null;
                    if (data?.deal) defaultValue = { value: data.deal.id, label: data.deal.title };
                    return (
                      <SearchSelect
                        className="h-[40px] block w-full border-gray-300 rounded-md  focus:ring-0  sm:text-sm focus:border-purple-700"
                        placeholder="Search by title"
                        defaultValue={defaultValue}
                        configs={{ key: "deal" }}
                        onChange={(opt: any) => onChange(opt?.value || null)}
                        isClearable
                      />
                    );
                  }}
                />
              </FormGroup>
              <FormGroup
                labelWidthClass="sm:w-[150px]"
                label="Properties"
                errors={errors}
                field="property_ids"
                endIcon={
                  data?.properties?.length && data.properties[0].url
                    ? openLinkIcon(data.properties[0].url)
                    : undefined
                }
              >
                <Controller
                  name="property_ids"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    let defaultValue = null;
                    if (data?.properties?.length) {
                      defaultValue = data?.properties.map((i: IProperty) => ({
                        value: i.id,
                        label: `${i.reference} (${i.type} for ${i.action})`
                      }));
                    }

                    return (
                      <SearchSelect
                        configs={{ key: "property" }}
                        className="min-h-[40px] block w-full border-gray-300 rounded-md  focus:ring-0  sm:text-sm focus:border-purple-700"
                        placeholder="Search property ID"
                        defaultValue={defaultValue}
                        onChange={(opt: any) => {
                          onChange(opt?.map((i: any) => i.value) || null);
                        }}
                        defaultOptions={
                          configs?.propertyOptions?.length
                            ? configs?.propertyOptions.map((i: IProperty) => ({
                                value: i.id,
                                label: `${i.reference} (${i.type} for ${i.action})`
                              }))
                            : []
                        }
                        isMulti
                        isClearable
                      />
                    );
                  }}
                />
              </FormGroup>
              <FormGroup
                labelWidthClass="sm:w-[150px]"
                label="Projects"
                errors={errors}
                field="project_ids"
                endIcon={
                  data?.projects?.length
                    ? openLinkIcon(`/projects/${data?.projects[0]?.id}`)
                    : undefined
                }
              >
                <Controller
                  name="project_ids"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    let defaultValue = null;
                    if (data?.projects?.length) {
                      defaultValue = data?.projects.map((i: IProject) => ({
                        value: i.id,
                        label: i.name
                      }));
                    }

                    return (
                      <SearchSelect
                        configs={{ key: "projects" }}
                        className="min-h-[40px] block w-full border-gray-300 rounded-md  focus:ring-0  sm:text-sm focus:border-purple-700"
                        placeholder="Search project Name"
                        defaultValue={defaultValue}
                        onChange={(opt: any) => {
                          onChange(opt?.map((i: any) => i.value) || null);
                        }}
                        defaultOptions={
                          configs?.projectOptions?.length
                            ? configs?.projectOptions.map((i: IProject) => ({
                                value: i.id,
                                label: i.name
                              }))
                            : []
                        }
                        isMulti
                        isClearable
                      />
                    );
                  }}
                />
              </FormGroup>
              <FormGroup
                labelWidthClass="sm:w-[150px]"
                required={Object.keys(schemaObj[data.type]).includes("user_id")}
                label="Contact"
                errors={errors}
                field="user_id"
                endIcon={data?.user ? openLinkIcon(`/contacts/${data?.user?.id}`) : undefined}
              >
                <Controller
                  name="user_id"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    let defaultValue = null;
                    if (data?.user) defaultValue = { value: data.user_id, label: data.user.name };
                    return (
                      <SearchSelect
                        className="h-[40px] block w-full border-gray-300 rounded-md  focus:ring-0  sm:text-sm focus:border-purple-700"
                        placeholder="Search by name, email, phone"
                        defaultValue={defaultValue}
                        configs={{ key: SearchOptionKey.users }}
                        onChange={(opt: any) => onChange(opt?.value || null)}
                        isClearable
                      />
                    );
                  }}
                />
              </FormGroup>
              <FormGroup
                labelWidthClass="sm:w-[150px]"
                required={Object.keys(schemaObj[data.type]).includes("agent_id")}
                label="Assigned to"
                errors={errors}
                field="agent_id"
                endIcon={data?.agent ? openLinkIcon(`/contacts/${data?.agent?.id}`) : undefined}
              >
                {hasPermission || data?.is_editable ? (
                  <Controller
                    name="agent_id"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      let defaultValue = null;
                      if (data?.agent)
                        defaultValue = { value: data.agent_id, label: data.agent.name };
                      return (
                        <SearchSelect
                          className="h-[40px] block w-full border-gray-300 rounded-md  focus:ring-0  sm:text-sm focus:border-purple-700"
                          placeholder="Search by name, email, phone"
                          defaultValue={defaultValue}
                          configs={{ key: SearchOptionKey.agents }}
                          onChange={(opt: any) => onChange(opt?.value || null)}
                          isClearable
                        />
                      );
                    }}
                  />
                ) : (
                  // read only if not admin
                  <p className="text-gray-500">
                    {data?.agent ? `${data.agent.name}<${data.agent.email}>` : ""}
                  </p>
                )}
              </FormGroup>
              <FormGroup
                labelWidthClass="sm:w-[150px]"
                required={Object.keys(schemaObj[data.type]).includes("description")}
                label="Description"
                errors={errors}
                field="description"
              >
                <textarea
                  rows={7}
                  id="description"
                  autoFocus
                  {...register("description", { required: true })}
                  className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-0 sm:text-sm focus:border-purple-700"
                  placeholder="Add your notes..."
                />
              </FormGroup>
            </>
          ) : (
            <Controller
              key="html_editor"
              name="description"
              control={control}
              render={({ field: { onChange, value } }: any) => (
                <MyEditor
                  initialValue={value}
                  init={{
                    height: 400,
                    paste_data_images: true,
                    plugins: ["paste", "image", "lists", "link", "textcolor"],
                    toolbar: [
                      {
                        name: "",
                        items: ["link", "forecolor", "backcolor", "image"]
                      }
                    ]
                  }}
                  onImageUpload={async (blob: Blob) => {
                    try {
                      const formData = new FormData();
                      formData.append("file", blob);
                      formData.append("ref_id", data?.id?.toString() ?? "");
                      formData.append("ref_type", "task");

                      const res: any = await uploadFiles({ path: "media", data: formData });
                      if (!res?.data?.data) {
                        throw new Error("Failed to upload image. No response data.");
                      }

                      const media = res.data.data as IMedia;
                      return media.url;
                    } catch (error) {
                      console.error("Failed to upload image", error);
                      return "";
                    }
                  }}
                  onChange={(event: any) => onChange(event)}
                />
              )}
            />
          )}
        </div>

        <div className="pb-6 mt-7 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <button
            type="submit"
            disabled={isEditable || inAction}
            className={classNames(
              "inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:col-start-2 sm:text-sm",
              data.id === "new" || data.is_editable
                ? "bg-purple-600 hover:bg-purple-700 focus:ring-purple-500"
                : "cursor-not-allowed bg-gray-200 focus:ring-gray-300",
              inAction ? "opacity-50 cursor-default" : ""
            )}
          >
            {data.id === "new" ? "Create now" : "Update"}
          </button>
          <button
            type="button"
            className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:col-start-1 sm:text-sm"
            onClick={args.handleCancel}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

const openLinkIcon = (url: string) => {
  return (
    <a href={url ?? "#"} target="_blank" rel="noreferrer">
      <ArrowUpRightFromSquareIcon className="w-4 h-3 ml-1 font-semibold cursor-pointer hover:text-gray-600 svg-inline--fa fa-arrow-up-right-from-square" />
    </a>
  );
};
